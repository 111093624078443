import { useParams } from '@remix-run/react';
import { format } from 'date-fns';
import { type ReactNode, useMemo } from 'react';
import useSWRImmutable from 'swr/immutable';

import { MediaType } from '@lp-lib/media';

import { GlobalLoading } from '../../components/GlobalLoading';
import { useUpcomingGlobalRound } from '../../components/GlobalPairingTournament';
import { Header } from '../../components/Header';
import { Loading } from '../../components/Loading';
import { useTitle } from '../../hooks/useTitle';
import { apiService } from '../../services/api-service';
import { type OnboardTask } from '../../types/onboard';
import { makeTitle } from '../../utils/common';
import { TimeUtils } from '../../utils/time';
import { MediaViewer } from '../Media/MediaViewer';

function Container(props: { children: ReactNode }): JSX.Element {
  return (
    <div className='absolute z-5 w-full h-full flex items-center justify-center'>
      {props.children}
    </div>
  );
}

function UmcompingGlobalRound(): JSX.Element {
  const { data: round, isValidating } = useUpcomingGlobalRound();
  if (isValidating) return <Loading />;
  return (
    <div className='font-bold'>
      <span>Next Round Starts: </span>
      <span className='text-tertiary'>
        {round ? format(new Date(round.startedAt), 'eeee, MMMM do') : 'Soon'}
      </span>
    </div>
  );
}

function TaskBody(props: { task: OnboardTask }): JSX.Element | null {
  const { task } = props;
  if (!task.body) return null;
  return (
    <div className='flex flex-col items-start justify-center text-sm lp-sm:text-base pr-10'>
      {task.body.sections.map((s, i) => (
        <div key={i} className='mb-4'>
          <div className='font-bold'>{s.header}</div>
          <ul>
            {s.items.map((item, i) => (
              <li key={i} className='list-disc list-inside'>
                {item}
              </li>
            ))}
          </ul>
        </div>
      ))}
      {task.body.showNextRound && (
        <div className='mt-4'>
          <UmcompingGlobalRound />
        </div>
      )}
      {task.body.timeToCompleteInSecs > 0 && (
        <div className='mt-2'>
          <div className='font-bold'>Time to Complete:</div>
          <div>
            {TimeUtils.DurationFormattedHumanMinutes(
              task.body.timeToCompleteInSecs * 1000
            )}
          </div>
        </div>
      )}
    </div>
  );
}

function TaskMedia(props: { task: OnboardTask }): JSX.Element | null {
  const { task } = props;
  // MediaViewer for video already used the aspect raito internally
  const mediaContainerClassName =
    task.media?.type === MediaType.Video ? '' : 'aspect-w-16 aspect-h-9 w-4/5';

  // TODO(jialin): A hard code one for MVP version
  const showLiveBanner = task.slug === 'schedule-a-live-hosted-event';
  return (
    <>
      <div className={`${mediaContainerClassName}`}>
        <div>
          {task.media ? (
            <MediaViewer media={task.media} loadingStyle='global' />
          ) : (
            <div className='flex items-center justify-center'>
              No Media Preview
            </div>
          )}
        </div>
      </div>
      {showLiveBanner && (
        <div className='lobby-instruction h-16 flex flex-col items-center justify-center'>
          <div className='text-white text-xl font-bold'>
            Unlimited events for subscribers
          </div>
          <div className='text-tertiary text-sms'>
            Typically costs $600 or more per event.
          </div>
        </div>
      )}
    </>
  );
}

function Action(props: {
  action: NonNullable<OnboardTask['primaryAction']>;
  kind: 'primary' | 'secondary';
}): JSX.Element {
  const { action, kind } = props;
  return (
    <a
      className={`w-100 h-12 flex items-center justify-center font-medium ${
        kind === 'primary' ? 'btn-primary' : ''
      }`}
      href={action.url}
    >
      {action.text}
    </a>
  );
}

function formatTitle(title: string, val: string): string {
  return title.replaceAll(/\*([^*]+)\*/g, val);
}

function Title(props: { title: string }): JSX.Element {
  const { title } = props;
  const formatted = useMemo(() => {
    return formatTitle(title, "<span class='text-primary'>$1</span>");
  }, [title]);

  return <div dangerouslySetInnerHTML={{ __html: formatted }}></div>;
}

function Task(props: { taskId: string }): JSX.Element | null {
  const { taskId } = props;
  const {
    data: task,
    isValidating,
    error,
  } = useSWRImmutable(`/onboard/tasks/${taskId}`, async () => {
    const resp = await apiService.onboard.getOnboardTask(taskId);
    return resp.data.onboardTask;
  });
  const normalizedTitle = useMemo(
    () => formatTitle(task?.title ?? '', '$1'),
    [task?.title]
  );

  useTitle(makeTitle(normalizedTitle), { disable: !task });

  if (isValidating) return <GlobalLoading />;
  if (error || !task) {
    return (
      <Container>
        <div className='text-white'>
          Fail to load data, please refresh to try again.
        </div>
      </Container>
    );
  }

  return (
    <Container>
      <div className='w-full flex flex-col items-center justify-center text-white'>
        <header className='w-full flex items-center justify-center'>
          {task.iconUrl && <img src={task.iconUrl} alt='icon' />}
          <div className='text-3.5xl font-bold'>
            <Title title={task.title} />
          </div>
        </header>
        {task.subtitle && (
          <div
            className={`text-xl font-medium w-170 text-center ${
              task.iconUrl ? 'ml-16' : ''
            }`}
          >
            {task.subtitle}
          </div>
        )}
        <section className='w-full flex items-center justify-center gap-2 px-20 2xl:px-30 my-16 lp-sm:my-20 2xl:my-24'>
          <div className='w-1/2'>
            <TaskBody task={task} />
          </div>
          <div className='w-1/2'>
            <TaskMedia task={task} />
          </div>
        </section>
        <footer className='flex flex-col items-center justify-center'>
          {task.primaryAction && (
            <Action action={task.primaryAction} kind='primary' />
          )}
          {task.secondaryAction && (
            <Action action={task.secondaryAction} kind='secondary' />
          )}
        </footer>
      </div>
    </Container>
  );
}

// eslint-disable-next-line import/no-default-export
export default function OnboardTaskDetail(): JSX.Element | null {
  const { taskId = '' } = useParams<'taskId'>();
  return (
    <div className='h-full w-full bg-onboard-task bg-no-repeat bg-cover bg-center relative'>
      <div className='w-full h-full absolute inset-0 bg-black bg-opacity-70 z-0'></div>
      <Header />
      <Task taskId={taskId} />
    </div>
  );
}
